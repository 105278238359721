<template>
    <div>
      <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
      <page-container class="nav-page-container">
        <p v-html="iframeUrl"></p>
      </page-container>
    </div>
</template>

<script>
export default {
    name: 'EAgreement',

    data() {
        return {
            iframeUrl: this.$route.query.iframeUrl
        };
    },

    mounted() {
        // console.log("00000000",this.iframeUrl);
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
.__page-container {
  padding: 12px;
}
</style>